import React, { useState, useEffect } from "react";
import SimpleSankey from "../common/plots/SimpleSankey";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Container, Form, Row, Col, Modal, Button } from "react-bootstrap";

// this module demonstrates how choice of leverage affects

const defaultData = {
  nodes: [
    {
      id: "Current Assets",
      color: "hsl(113, 70%, 50%)",
    },
    {
      id: "Fixed Assets",
      color: "hsl(115, 70%, 50%)",
    },

    {
      id: "Assets",
      color: "hsl(113, 70%, 50%)",
    },
    {
      id: "Liabilities",
      color: "hsl(115, 70%, 50%)",
    },
    {
      id: "Equity",
      color: "hsl(75, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Assets",
      target: "Liabilities",
      value: 193,
    },
    {
      source: "Assets",
      target: "Equity",
      value: 190,
    },
  ],
};

const colors = {
  Equity: "#ff3300",
  Assets: "#66ccff",
  Liabilities: "#ff0066",
  "Current Assets": "#cceeff",
  "Fixed Assets": "#00aaff",
  "Current Liabilities": "#ff80b3",
  "Long-term Liabilities": "#cc0052",
  "Retained Earnings": "#ffd699",
  "Common Stock": "#cc2900",
};

export default function Balance() {
  const [data, setData] = useState(defaultData);
  const [equity, setEquity] = useState(50);
  const [debt, setDebt] = useState(50);

  const [render, setRender] = useState(false);
  const [assetDetail, setAssetDetail] = useState(false);
  const [debtDetail, setDebtDetail] = useState(false);
  const [equityDetail, setEquityDetail] = useState(false);
  const [currentAssetRatio, setCurrentAssetRatio] = useState(50);
  const [currentLiabilityRatio, setCurrentLiabilityRatio] = useState(50);
  const [reRatio, setReRatio] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (id) => {
    console.log(id);
    if (id === "Equity") {
      setEquityDetail(!equityDetail);
    }
    if (id === "Assets") {
      setAssetDetail(!assetDetail);
    }
    if (id === "Liabilities") {
      setDebtDetail(!debtDetail);
    }

    if (id === "Current Assets") {
      setModalMsg(
        "Current Assets include cash, treasury bills/bonds, accounts receivable, inventory, prepaid expenses"
      );
      setShowModal(true);
    }

    if (id === "Fixed Assets") {
      setModalMsg(
        "Fixed assets include property, plant and equipment (PP&E). We will also group intangible assets and long-term investments in this group."
      );
      setShowModal(true);
    }

    if (id === "Current Liabilities") {
      setModalMsg(
        "Current liabilities include accounts receivable, short term debt (including maturing long-term debt), dividends payable, interest payable, taxes owed within 1 year."
      );
      setShowModal(true);
    }

    if (id === "Long-term Liabilities") {
      setModalMsg("Long-term liabilities are those due after one year.");
      setShowModal(true);
    }

    if (id === "Retained Earnings") {
      setModalMsg(
        "Retained earnings are historical profits earned by the company that have not been distributed as dividends."
      );
      setShowModal(true);
    }
    if (id === "Common Stock") {
      setModalMsg("Common stock is the equity raised from shareholders.");
      setShowModal(true);
    }
    setRender(!render);
  };

  useEffect(() => {
    var newData = {
      nodes: [
        {
          id: "Assets",
          color: "hsl(113, 70%, 50%)",
        },
        {
          id: "Liabilities",
          color: "hsl(115, 70%, 50%)",
        },
        {
          id: "Equity",
          color: "hsl(75, 70%, 50%)",
        },
      ],
      links: [
        {
          source: "Assets",
          target: "Liabilities",
          value: parseFloat(debt) / 100,
        },
        {
          source: "Assets",
          target: "Equity",
          value: parseFloat(equity) / 100,
        },
      ],
    };
    if (assetDetail) {
      newData.nodes.push({
        id: "Current Assets",
        color: "hsl(113, 70%, 50%)",
      });
      newData.nodes.push({
        id: "Fixed Assets",
        color: "hsl(115, 70%, 50%)",
      });
      newData.links.push({
        source: "Current Assets",
        target: "Assets",
        value: parseFloat(currentAssetRatio) / 100,
      });
      newData.links.push({
        source: "Fixed Assets",
        target: "Assets",
        value: 1 - parseFloat(currentAssetRatio) / 100,
      });
    }

    if (debtDetail) {
      newData.nodes.push({
        id: "Current Liabilities",
        color: "hsl(113, 70%, 50%)",
      });
      newData.nodes.push({
        id: "Long-term Liabilities",
        color: "hsl(115, 70%, 50%)",
      });
      newData.links.push({
        source: "Liabilities",
        target: "Current Liabilities",
        value: ((parseFloat(debt) / 100) * currentLiabilityRatio) / 100,
      });
      newData.links.push({
        source: "Liabilities",
        target: "Long-term Liabilities",
        value: (parseFloat(debt) / 100) * (1 - currentLiabilityRatio / 100),
      });
    }

    if (equityDetail) {
      newData.nodes.push({
        id: "Retained Earnings",
        color: "hsl(113, 70%, 50%)",
      });
      newData.nodes.push({
        id: "Common Stock",
        color: "hsl(115, 70%, 50%)",
      });
      newData.links.push({
        source: "Equity",
        target: "Retained Earnings",
        value: ((1 - parseFloat(debt) / 100) * reRatio) / 100,
      });
      newData.links.push({
        source: "Equity",
        target: "Common Stock",
        value: (1 - parseFloat(debt) / 100) * (1 - reRatio / 100),
      });
    }

    setData(newData);
    setRender(!render);
    return () => {};
  }, [
    equity,
    debt,
    assetDetail,
    equityDetail,
    debtDetail,
    currentAssetRatio,
    currentLiabilityRatio,
    reRatio,
  ]);

  return (
    <Container>
      <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Hint:</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{modalMsg}</p>
        </Modal.Body>
      </Modal>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          <SimpleSankey
            data={data}
            onClick={(data) => {
              handleClick(data.id);
            }}
            colors={(data) => colors[data["id"]]}
            nodeThickness={40}
            nodeTooltip={(node) => {
              
              return <span>Custom tooltip for node: {node.label}</span>;
            }}
          />
        </div>
      </Row>

      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              {" "}
              <b>Leverage (Equity / Assets %)</b>
            </Form.Label>

            <RangeSlider
              value={equity}
              onChange={(e) => {
                setEquity(e.target.value);
                setDebt(100 - e.target.value);
              }}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>
              <b>Current Assets / Assets (%)</b>
            </Form.Label>
            <RangeSlider
              value={currentAssetRatio}
              onChange={(e) => setCurrentAssetRatio(e.target.value)}
              min={0}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <b>Current Liabilities / Liabilities (%)</b>{" "}
            </Form.Label>
            <RangeSlider
              value={currentLiabilityRatio}
              onChange={(e) => setCurrentLiabilityRatio(e.target.value)}
              min={0}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>
              <b>Retained Earnings / Equity (%)</b>
            </Form.Label>
            <RangeSlider
              value={reRatio}
              onChange={(e) => setReRatio(e.target.value)}
              min={0}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <Row className="justify-content-center">
        <Button variant="outline-dark" onClick={handleShow}>
          <h5>Info</h5>
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>Click on each node to expand / show information</li>
             
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
}
