import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { createCFPlot } from "../../functions/CFFunctions";

import CFInputForm from "../common/forms/CFInputForm";

import SimpleBar from "../common/plots/SimpleBar";

export default function CFVisualize({ input, rerender, handleShow }) {
  // a module to visualize cash flows, including perpetuity, annuity, bonds, stocks

  const modifyCFArr = (CF, pos) => {
    // this function changes

    const newCFArr = CFArr;
    newCFArr[pos] = CF;
    setCFArr(newCFArr);
  };

  const [CFArr, setCFArr] = useState([]);
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender
  const [from, setFrom] = useState(0); // from cash flow, default set to 0
  const [to, setTo] = useState("Auto"); // last cash flow
  const [setting, setSetting] = useState(false);

  useEffect(() => {
    // initialize the CF array
    const temp = input.forms.map((f, i) => ({ index: i, name: f.name }));
    setCFArr(temp);

    return () => {};
  }, [input, rerender]);

  const handlePlot = () => {
    const plotDataTemp = [];
    plotDataTemp["data"] = createCFPlot(CFArr, from, to);

    plotDataTemp["bars"] = input.forms.map((f) => f.name);

    plotDataTemp["ylabel"] = input.unit;
    plotDataTemp["xlabel"] = input.title;

    setPlotData(plotDataTemp);

    setRender(!render);
  };

  return (
    <Container>
      <Row>
        {" "}
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && <SimpleBar data={plotData} />}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          CFArr[i] && (
            <div key={Math.random() + Date.now()}>
              <h4>
                <Badge variant="primary">{CFArr[i].name}</Badge>
              </h4>
              <CFInputForm
                modifyCFArr={modifyCFArr}
                pos={i}
                CF={CFArr[i]}
                newCal={newCal}
                setNewCal={setNewCal}
                disabled={f.disabled}
              />
            </div>
          )
      )}
      <Row className = "justify-content-center">
        <Button variant="outline-primary" onClick={handlePlot}>
          <h5>Plot</h5>
        </Button>

        <Button
          variant="outline-dark"
          className="ml-5"
          onClick={() => setSetting(!setting)}
        >
          <h5>Display Setting</h5>
        </Button>

        <Button onClick={handleShow} className = "ml-5"> <h5>Customize Cash Flow</h5> </Button>
        
      </Row>
      {setting && (
        <Form.Row>
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <b> Plot CFs From </b>
            </Form.Label>
            <Form.Control
              placeholder={0}
              value={from}
              onChange={(e) => {
                setFrom(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <b> Plot CFs to </b>
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                setTo(e.currentTarget.value);
                console.log(e.currentTarget.value);
              }}
              value={to}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Group as={Row}></Form.Group>
          </Form.Group>
        </Form.Row>
      )}
    </Container>
  );
}
