import React from "react";
import { Helmet } from "react-helmet";

export default function SEO() {
  return (
    <div>
      <Helmet>
        <title>FIVIZ - Teaching Finance Visually</title>
        <meta
          name="description"
          content="Interactive modules to visualize finance concepts. Visualize cash flow, balance sheets, and present values."
        />
        <meta
          name="keywords"
          content="interactive,finance,visualize,customize,balance sheet, present value, cash flow"
        />
      </Helmet>
    </div>
  );
}
