import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Badge,
} from "react-bootstrap";
import {
  createBankBalance,
  createBankBalanceSimpleInterest,
} from "../../functions/TvmFunctions";

import { merge } from "../../functions/Utilities";

import PvFvForm from "../common/forms/PvFvForm";
//import PvFvSimpleIntForm from "./common/forms/PvFvSimpleIntForm";
import SimpleBar from "../common/plots/SimpleBar";
//const Finance = require("tvm-financejs");

export default function Tvm({ input, rerender }) {
  const simplifyTvm = (plotData) => {
    const plotDataTemp = plotData;
    var data = plotData["data"];

    plotDataTemp["data"] = [data[0], data[data.length - 1]];

    return plotDataTemp;
  };

  useEffect(() => {
    // initialize the CF array
    const temp = input.forms.map((f, i) => ({ index: i, name: f.name }));
    setTvmArr(temp);

    return () => {};
  }, [input, rerender]);

  const modifyTvmArr = (tvm, pos) => {
    // this function changes
    const newTvmArr = tvmArr;
    newTvmArr[pos] = tvm;
    setTvmArr(newTvmArr);
  };

  // const [tvm, setTvm] = useState({});
  // const [tvmSimp, setTvmSimp] = useState({});
  const [tvmArr, setTvmArr] = useState([]);
  const [plotDataSimple, setPlotDataSimple] = useState();
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender

  useEffect(() => {
    // initialize the tvm array
    const temp = input.forms.map((f, i) => ({ index: i }));

    temp[0].pv = 1;
    temp[0].PV = "1";
    temp[0].rateEff = 5;
    temp[0].RATEEFF = "5";
    temp[0].period = 5;
    temp[0].PERIOD = "5";
    temp[0].fv = 1.276;
    temp[0].FV = "1.276";
    temp[0].pmt = 0;
    temp[0].PMT = "0";

    setTvmArr(temp);
    setNewCal(!newCal);

    return () => {};
  }, []);

  useEffect(() => {
    {
      // console.log(tvmArr);
      const plotDataTemp = [];
      plotDataTemp["data"] = merge(
        input.forms.map((f, i) =>
          tvmArr[i]
            ? f.simple
              ? createBankBalanceSimpleInterest(tvmArr[i], f.name)
              : createBankBalance(tvmArr[i], f.name)
            : createBankBalance({ period: -1, pv: 0, pmt: 0, rateEff: 1 })
        ),
        "name",
        "acctVal"
      );

      plotDataTemp["bars"] = input.forms.map((f, i) => f.name);

      plotDataTemp["ylabel"] = input.unit;
      plotDataTemp["xlabel"] = input.title;
      if (input.simplePlot) {
        setPlotDataSimple(simplifyTvm(plotDataTemp));
      }
      setPlotData(plotDataTemp);
      // console.log(plotDataTemp);
      setRender(!render);
    }
    return () => {};
  }, [newCal]);

  return (
    <Container>
      <Row>
        {" "}
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && <SimpleBar data={plotData} />}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          tvmArr[i] && (
            <>
              <Badge variant="primary" className="mb-3">
                <h5>{f.name}</h5>
              </Badge>

              {
                <PvFvForm
                  modifyTvmArr={modifyTvmArr}
                  pos={i}
                  tvm={tvmArr[i]}
                  newCal={newCal}
                  setNewCal={setNewCal}
                  disabled={f.disabled}
                  simple={f.simple}
                />
              }
            </>
          )
      )}
    </Container>
  );
}
