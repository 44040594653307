import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  numberWithCommas,
  numberWithCommasAndDigits,
} from "../../../functions/Display";
import { tvmFunc, simpleTvmFunc } from "../../../functions/TvmFunctions";
//const Finance = require("tvm-financejs");

export default function PvFvForm({
  tvm,
  modifyTvmArr,
  pos,
  newCal,
  setNewCal,
  disabled,
  simple
}) {
  const [render, setRender] = useState(false);

  //const disabled = ['rateEff'];
  const handleChange = (name, val) => {
    var newTvm = tvm;

    if (val === "") {
      newTvm[name.toUpperCase()] = "";
      newTvm[name] = null;
      modifyTvmArr(newTvm, pos);
      setRender(!render);
    } else {
      var thisval = parseFloat(val.toString().replace(/,/g, ""));

      newTvm[name.toUpperCase()] = numberWithCommas(thisval);
      if (val.toString().charAt(val.toString().length - 1) === ".") {
        newTvm[name.toUpperCase()] = newTvm[name.toUpperCase()] + ".";
      }
      newTvm[name] = thisval;

      modifyTvmArr(newTvm, pos);

      setRender(!render);
    }
  };

  const handleSubmit = (target) => {
    //console.log(tvm);

    var newTvm;
    if (!simple) {
      newTvm = tvmFunc(tvm, target);
    } else {
      newTvm = simpleTvmFunc(tvm, target);
    }
    modifyTvmArr(newTvm, pos);
    //console.log(newTvm)
    newTvm[target.toUpperCase()] = numberWithCommasAndDigits(newTvm[target], 2);

    setRender(!render);
    setNewCal(!newCal);
  };

  return (
    <Form>
      <Form.Row>
        {!disabled.includes("rateEff") && (
          <Form.Group as={Col} controlId="rateEff">
            <Form.Label>
              {" "}
              <Button
                size="sm"
                variant="outline-dark"
                onClick={() => handleSubmit("rateEff")}
              >
                <b>Effective Rate (%)</b>
              </Button>{" "}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                handleChange("rateEff", e.currentTarget.value);
              }}
              value={tvm.RATEEFF ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("period") && (
          <Form.Group as={Col} controlId="period">
            <Form.Label>
              {" "}
              <Button
                size="sm"
                variant="outline-dark"
                onClick={() => handleSubmit("period")}
              >
                <b>Period</b>{" "}
              </Button>{" "}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                handleChange("period", e.currentTarget.value);
              }}
              value={tvm.PERIOD ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("pv") && (
          <Form.Group as={Col} controlId="pv">
            <Form.Label>
              <Button
                size="sm"
                variant="outline-dark"
                onClick={() => handleSubmit("pv")}
              >
                {" "}
                <b>Present Value</b>{" "}
              </Button>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("pv", e.currentTarget.value);
              }}
              value={tvm.PV ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("pmt") && (
          <Form.Group as={Col} controlId="pmt">
            <Form.Label>
              <Button
                size="sm"
                variant="outline-dark"
                onClick={() => handleSubmit("pmt")}
              >
                {" "}
                <b>Payment</b>{" "}
              </Button>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("pmt", e.currentTarget.value);
              }}
              value={tvm.PMT ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("fv") && (
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <Button
                size="sm"
                variant="outline-dark"
                onClick={() => handleSubmit("fv")}
              >
                {" "}
                <b>Future Value </b>
              </Button>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("fv", e.currentTarget.value);
              }}
              value={tvm.FV ?? null}
            />
          </Form.Group>
        )}
      </Form.Row>
    </Form>
  );
}
