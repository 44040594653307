import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
//import Drawer from "rc-drawer";

const allTags = {
  bond: "Bonds",
  stock: "Stocks",
  cf: "Cash flows",
  statement: "Financial Statements",
  cfstatement: "Statement of Cash Flow",
  incstatement: "Income Statement",
  balsheet: "Balance Sheet",
  tvm: "Time Value of Money",
  customize: "Customizable",
  growth: "Growth",
  leverage: "Leverage",
  mortgage: "Mortgage",
  profit: "Profitability",
};

const AllLinks = [
  {
    name: "Bond Cash Flow Visualization",
    address: "/bond",
    description: "Visualize bond payments on the time line.",
    tags: ["cf", "bond"],
  },
  {
    name: "Stock with Two-Stage Growth",
    address: "/stock",
    description:
      "A tool to visualize cash flows of two-stage stocks payments. ",
    tags: ["stock", "cf", "growth"],
  },
  {
    name: "Cash flow Visualization Tool",
    address: "/cfs",
    description:
      "A tool to flexibly visualize multiple cash flow payment on the time line",
    tags: ["cf", "growth", "customize"],
  },
  {
    name: "Visualize Present Value and Future Value",
    address: "/tvm",
    description:
      "Visualize Present Value and Future Value in an investment account.",
    tags: ["tvm"],
  },
  {
    name: "Present Value Composition",
    address: "/PmtPct",
    description: "A tool to view PV composition",
    tags: ["tvm", "bond"],
  },
  {
    name: "Mortgage Payment Decomposition",
    address: "/mtgpmt",
    description: "Decomposing mortgage payment into principle and interest",
    tags: ["mortgage", "tvm"],
  },
  {
    name: "Bond Pricing",
    address: "/bondpricing",
    description: "Demonstrate bond pricing theorem",
    tags: ["bond", "tvm"],
  },
  {
    name: "Cash from Assets and Consequence of Leverage",
    address: "/cashflow",
    description: "Demonstrate how cash flow changes given the leverage",
    tags: ["statement", "leverage", "cfstatement"],
  },
  {
    name: "Visualizing Income Statement and Firm Profits",
    address: "/income",
    description:
      "This module demonstrates how various factors affect firm earnings and profitability.",
    tags: ["statement", "leverage", "incstatement", "profit"],
  },
  {
    name: "Visualize Balance Sheet Items",
    address: "/balance",
    description: "This module visualizes balance sheet items.",
    tags: ["statement", "balsheet", "leverage"],
  },

  //   {
  //     name: "Bond Cash Flow Visualization",
  //     address: "/bond",
  //     description: "A tool to visualize bond payment",
  //   },
];

export default function Home() {
  const clearFilter = () => {
    const filterTemp = {};

    Object.keys(allTags).forEach((key, index) => (filterTemp[key] = true));
    setFilter(filterTemp);
  };
  useEffect(() => {
    clearFilter();
    return () => {};
  }, []);

  const [filter, setFilter] = useState({});

  const filterCheck = (tags) => {
    var filterVal = true;
    tags.forEach((t) => (filterVal = filterVal && filter[t]));

    return filterVal;
  };

  const changeFilter = (tag) => {
    const newFilter = filter;
    newFilter[tag] = !newFilter[tag];
    setFilter(newFilter);
    setRender(!render);
  };

  const [render, setRender] = useState(false);
  
  return (
    <>
      <Container fluid style={{minHeight: 100}}>
        <Row className="">
          <Col className="bg-light" md="3" xs="12">
            <Row className="justify-content-center mt-4">
              <h4 className="text-primary">Topic Filter</h4>
            </Row>

            <Row className="justify-content-begin ml-3 mt-1 mb-3">
              {Object.keys(allTags).map((key, index) => (
                <>
                  {" "}
                  <Button
                    className="ml-3 mt-3 mr-3"
                    size="sm"
                    variant={filter[key] ? "outline-primary" : "primary"}
                    onClick={() => {
                      changeFilter(key);
                    }}
                    key={key}
                  >
                    #{allTags[key]}
                  </Button>{" "}
                </>
              ))}
            </Row>
            <Row className="justify-content-begin ml-3 mt-1 mb-3">
              <Button
                variant="outline-dark"
                className="ml-3 mt-3"
                onClick={clearFilter}
              >
                Clear
              </Button>
            </Row>
          </Col>
          <Col className="mb-5" md="9" xs="12">
            <Row className="mt">
              {AllLinks.map(
                (l) =>
                  filterCheck(l.tags) && (
                    <Col md="4" xs="12" className="mt-4">
                      <Card
                        className="ml-2 h-100"
                        border="primary"
                        style={{ minWdith: "10rem" }}
                        key={l.name}
                      >
                        <Card.Subtitle className="mt-3 ml-3">
                          {" "}
                          <Link to={l.address}>{l.name}</Link>
                        </Card.Subtitle>
                        <Card.Body>
                          <Card.Text>{l.description}</Card.Text>

                          {l.tags.map((t) => (
                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => changeFilter(t)}
                            >
                              #{allTags[t]}
                            </Button>
                          ))}
                        </Card.Body>
                      </Card>
                    </Col>
                  )
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
