import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { createPvPmt } from "../../functions/TvmFunctions";

import PvFvInputForm from "../common/forms/PvFvInputForm";

import StackedBar from "../common/plots/StackedBar";
//const Finance = require("tvm-financejs");

const input = {
  unit: "Percentage",
  firstT: 3,
  title: "Percentage of PV from Future Cash Flows",
  forms: [
    { name: "Account Value (Compound)" },
    { name: "Account Value (Compound)" },
  ],
};

export default function PmtPct() {
  const modifyTvmArr = (tvm, pos) => {
    // this function changes
    const newTvmArr = tvmArr;
    newTvmArr[pos] = tvm;
    setTvmArr(newTvmArr);
  };

  const [tvmArr, setTvmArr] = useState([]);
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender

  useEffect(() => {
    // initialize the tvm array
    const temp = input.forms.map((f, i) => ({ index: i }));

    temp[0].period = 10;
    temp[0].PERIOD = "10";
    temp[0].pmt = 10;
    temp[0].PMT = "10";
    temp[0].start = 1;
    temp[0].START = "1";
    temp[0].g = 0;
    temp[0].G = "0";
    temp[0].rateEff = 5;
    temp[0].RATEEFF = "5";
    temp[0].fv = 0;
    temp[0].FV = "0";

    setTvmArr(temp);
    setNewCal(!newCal);
    return () => {};
  }, []);

  useEffect(() => {
    {
      //const result = createBankBalance(tvm);
      //const resultSimp = createBankBalanceSimpleInterest(tvmSimp);
      // console.log(result);
      const plotDataTemp = [];
      plotDataTemp["data"] = input.forms.map((f, i) =>
        tvmArr[i]
          ? createPvPmt(tvmArr[i], input.firstT, i)
          : createPvPmt(
              { period: input.firstT, pv: 0, pmt: 0, g: 0, rateEff: 1, fv: 0 },
              input.firstT,
              i
            )
      );

      //console.log(plotDataTemp)

      plotDataTemp["ylabel"] = input.unit;
      plotDataTemp["xlabel"] = input.title;
      const keys = [];

      for (let i = 1; i <= input.firstT; i++) {
        keys.push(`CF ${i.toString()}`);
      }
      keys.push("CF Terminal");
      keys.push("CF Remaining");

      plotDataTemp["keys"] = keys;
      setPlotData(plotDataTemp);
      // console.log(plotDataTemp);
      setRender(!render);
    }
    return () => {};
  }, [newCal]);

  return (
    <Container>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && (
            //   <StackedBar props={plotData} />
            <StackedBar data={plotData} />
          )}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          tvmArr[i] && (
            <PvFvInputForm
              modifyTvmArr={modifyTvmArr}
              pos={i}
              tvm={tvmArr[i]}
              newCal={newCal}
              setNewCal={setNewCal}
              disabled={["pv"]}
            />
          )
      )}
      <Row className="justify-content-center">
        <Button variant="outline-primary" onClick={() => setNewCal(!newCal)}>
          <h5>Plot</h5>
        </Button>
      </Row>
    </Container>
  );
}
