import React, { useState, useEffect } from "react";
import SimpleSankey from "../common/plots/SimpleSankey";
import SimpleBar from "../common/plots/SimpleBar";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Container, Form, Row, Col, Modal, Button } from "react-bootstrap";

// this module demonstrates a decomposition of earnings

const defaultData = {
  nodes: [
    {
      id: "Sales",
      color: "hsl(113, 70%, 50%)",
    },
    {
      id: "Costs and Expenses",
      color: "hsl(115, 70%, 50%)",
    },
    {
      id: "Depreciation",
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "EBIT",
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "Interest",
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "Taxable Income",
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "Taxes",
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "Net Income",
      color: "hsl(75, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Sales",
      target: "Costs and Expenses",
      value: 50,
    },
    {
      source: "Sales",
      target: "Depreciation",
      value: 30,
    },
    {
      source: "Sales",
      target: "EBIT",
      value: 20,
    },
    {
      source: "EBIT",
      target: "Interest",
      value: 190,
    },
    {
      source: "EBIT",
      target: "Taxable Income",
      value: 190,
    },
    {
      source: "Taxable Income",
      target: "Taxes",
      value: 190,
    },
    {
      source: "Taxable Income",
      target: "Net Income",
      value: 190,
    },
  ],
};

const defaultBarData = {
  data: [
    {
      name: "Profitability",
      ROE: 0.5,
      ROA: 0.5,
    },
  ],
  bars: ["ROE", "ROA"],
};

export default function Income() {
  const [pm, setPm] = useState(50);
  const [sankeyData, setSankeyData] = useState(defaultData);
  const [barData, setBarData] = useState(defaultBarData);
  const [int, setInt] = useState(10);
  const [d2a, setD2a] = useState(10);
  const [taxRate, setTaxRate] = useState(21);
  const [render, setRender] = useState(false);
  const [show, setShow] = useState(false);
  const [roe, setRoe] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [payout, setPayout] = useState(50);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var newSankeyData = {
      nodes: [
        {
          id: "Sales",
          color: "hsl(113, 70%, 50%)",
        },
        {
          id: "Costs and Expenses",
          color: "hsl(115, 70%, 50%)",
        },
        {
          id: "Depreciation",
          color: "hsl(75, 70%, 50%)",
        },
        {
          id: "EBIT",
          color: "hsl(75, 70%, 50%)",
        },
        {
          id: "Interest",
          color: "hsl(75, 70%, 50%)",
        },
        {
          id: "Taxable Income",
          color: "hsl(75, 70%, 50%)",
        },
        {
          id: "Taxes",
          color: "hsl(75, 70%, 50%)",
        },
        {
          id: "Net Income",
          color: "hsl(75, 70%, 50%)",
        },
      ],

      links: [
        {
          source: "Sales",
          target: "Costs and Expenses",
          value: Math.round(((100 * (1 - pm / 100)) / 3) * 2),
        },
        {
          source: "Sales",
          target: "Depreciation",
          value: Math.round((100 * (1 - pm / 100)) / 3),
        },
        {
          source: "Sales",
          target: "EBIT",
          value: Math.round((100 * pm) / 100),
        },
        {
          source: "EBIT",
          target: "Interest",
          value: Math.round((((100 * d2a) / 100) * int) / 100),
        },
        {
          source: "EBIT",
          target: "Taxable Income",
          value: Math.round(
            (100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100
          ),
        },
        {
          source: "Taxable Income",
          target: "Taxes",
          value: Math.round(
            (((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) * taxRate) /
              100
          ),
        },
        {
          source: "Taxable Income",
          target: "Net Income",
          value: Math.round(
            ((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
              (1 - taxRate / 100)
          ),
        },
      ],
    };

    if (showDiv) {
      newSankeyData.nodes.push({ id: "Addition to Retained Earnings" });
      newSankeyData.nodes.push({ id: "Dividends" });

      newSankeyData.links.push({
        source: "Net Income",
        target: "Addition to Retained Earnings",
        value: Math.round(
          ((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
            (1 - taxRate / 100) *
            (1 - payout / 100)
        ),
      });

      newSankeyData.links.push({
        source: "Net Income",
        target: "Dividends",
        value: Math.round(
          ((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
            (1 - taxRate / 100) *
            (payout / 100)
        ),
      });
    }
    setSankeyData(newSankeyData);

    setBarData({
      data: [
        {
          name: "Profitability",
          ROE:
            Math.round(
              ((((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
                (1 - taxRate / 100)) /
                (100 - d2a)) *
                10000
            ) / 100,
          ROA:
            Math.round(
              ((((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
                (1 - taxRate / 100)) /
                100) *
                10000
            ) / 100,
        },
      ],
      xlabel: "",
      bars: ["ROE", "ROA"],
    });
    setRender(!render);
    return () => {};
  }, [pm, taxRate, int, d2a, payout, showDiv]);

  const handleSankeyClick = (data) => {
    console.log(data);
    if (data.id === "Net Income") {
      setShowDiv(!showDiv);
    }
  };

  return (
    <Container>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {roe ? (
            <SimpleBar data={barData} maxValue={100} minValue={-50} />
          ) : (
            <SimpleSankey
              data={sankeyData}
              vertical={true}
              onClick={handleSankeyClick}
            />
          )}
        </div>
      </Row>
      <Row className="justify-content-center">
        <Button
          variant="outline-primary"
          onClick={() => {
            setRoe(!roe);
          }}
        >
          {" "}
          <h5>{roe ? "Earnings Decomposition Chart" : "ROE Chart"} </h5>
        </Button>
      </Row>

      <Row className="justify-content-center">
        {((100 * pm) / 100 - (((100 * d2a) / 100) * int) / 100) *
          (1 - taxRate / 100) <
        0 ? (
          <h4>Negative Earning!</h4>
        ) : (
          ""
        )}
      </Row>
      <Form className="mt-3">
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <b>EBIT / Sales (%)</b>
            </Form.Label>
            <RangeSlider
              value={pm}
              onChange={(e) => setPm(e.target.value)}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>
              <b>Interest Rate (%) </b>
            </Form.Label>
            <RangeSlider
              value={int}
              onChange={(e) => setInt(e.target.value)}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              {" "}
              <b>Leverage (Debt / Assets %)</b>
            </Form.Label>
            <RangeSlider
              value={d2a}
              onChange={(e) => setD2a(e.target.value)}
              min={0}
              max={99.5}
              step={0.5}
              size="lg"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>
              <b>Tax Rate (%)</b>{" "}
            </Form.Label>
            <RangeSlider
              value={taxRate}
              onChange={(e) => setTaxRate(e.target.value)}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <b>Dividend Payout Ratio (%)</b>{" "}
            </Form.Label>
            <RangeSlider
              value={payout}
              onChange={(e) => setPayout(e.target.value)}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
      </Form>

      <Row className="justify-content-center">
        <Button variant="outline-dark" onClick={handleShow}>
          <h5>Info</h5>
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>Click on net income to show payout diagram</li>
              <li>
                Assumption: total assets = 100; costs of expenses / depreciation
                = 7/3{" "}
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
}
