import React, { useState, useEffect } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";

import PvFvInputForm from "../common/forms/PvFvInputForm";

import SimpleBar from "../common/plots/SimpleBar";
const Finance = require("tvm-financejs");

const input = {
  unit: "USD",
  title: "Bank Account Value",
  simplePlot: false,
  forms: [{ name: "Bond 1" }, { name: "Bond 2" }],
};
// this module demonstrates bond pricing theorem
function BondPricing() {
  const [oldInt, setOldInt] = useState(5);
  const [newInt, setNewInt] = useState(5);

  const modifyTvmArr = (tvm, pos) => {
    // this function changes
    const newTvmArr = tvmArr;
    newTvmArr[pos] = tvm;
    setTvmArr(newTvmArr);
  };

  const [tvmArr, setTvmArr] = useState([]);
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender

  useEffect(() => {
    // initialize the tvm array
    const temp = input.forms.map((f, i) => ({ index: i }));

    temp[0].fv = 1000;
    temp[0].FV = "1000";
    temp[0].period = 10;
    temp[0].PERIOD = "10";
    temp[0].rateEff = 5;
    temp[0].RATEEFF = "5";
    temp[0].pmt = 50;
    temp[0].PMT = "50";
    temp[1].fv = 1000;
    temp[1].FV = "1000";
    temp[1].period = 5;
    temp[1].PERIOD = "5";
    temp[1].rateEff = 5;
    temp[1].RATEEFF = "5";
    temp[1].pmt = 50;
    temp[1].PMT = "50";
    setTvmArr(temp);
    //console.log(temp);
    setNewCal(!newCal);

    return () => {};
  }, []);

  useEffect(() => {
    {
      console.log(tvmArr);
      const finance = new Finance();

      const plotDataTemp = [];
      plotDataTemp["data"] = input.forms.map((f, i) =>
        tvmArr[i]
          ? {
              name: f.name,
              "Initial PV":
                Math.round(
                  -finance.PV(
                    oldInt / 100,
                    tvmArr[i].period,
                    tvmArr[i].pmt,
                    tvmArr[i].fv
                  ) * 100
                ) / 100,
              "New PV":
                Math.round(
                  -finance.PV(
                    newInt / 100,
                    tvmArr[i].period,
                    tvmArr[i].pmt,
                    tvmArr[i].fv
                  ) * 100
                ) / 100,
            }
          : { name: f.name, "Initial PV": 0.01, "New PV": 0.01 }
      );

      plotDataTemp["ylabel"] = input.unit;
      plotDataTemp["xlabel"] = input.title;
      const keys = [];

      keys.push("Initial PV");
      keys.push("New PV");

      plotDataTemp["bars"] = keys;
      setPlotData(plotDataTemp);
      //console.log(plotDataTemp);
      setRender(!render);
    }
    return () => {};
  }, [newCal, newInt, oldInt]);

  return (
    <Container>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && <SimpleBar data={plotData} />}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          tvmArr[i] && (
            <PvFvInputForm
              modifyTvmArr={modifyTvmArr}
              pos={i}
              tvm={tvmArr[i]}
              newCal={newCal}
              setNewCal={setNewCal}
              disabled={["pv", "g", "rateEff"]}
              fvName="Face Value"
              pmtName="Coupon Payment"
              periodName="Number of Payments"
            />
          )
      )}
      <Row className="justify-content-center">
        <Button variant="outline-primary" onClick={() => setNewCal(!newCal)}>
          <h5>Submit Bond Information</h5>
        </Button>
      </Row>
      <Form>
        <Form.Group>
          <Form.Label>
            <b>Initial Interest Rate (%)</b>
          </Form.Label>

          <RangeSlider
            value={oldInt}
            onChange={(e) => setOldInt(e.target.value)}
            min={0.5}
            max={10}
            step={0.5}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>New Interest Rate (%)</b>
          </Form.Label>
          <RangeSlider
            value={newInt}
            onChange={(e) => setNewInt(e.target.value)}
            min={0.5}
            max={10}
            step={0.5}
          />
        </Form.Group>
      </Form>
    </Container>
  );
}

export default BondPricing;
