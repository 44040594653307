import firebase from "firebase";


var firebaseConfig = {
    apiKey: "AIzaSyBiArzsKcy1xYGxOzuiFjfAGXxCguBruBo",
    authDomain: "fiviz-cb1b6.firebaseapp.com",
    projectId: "fiviz-cb1b6",
    storageBucket: "fiviz-cb1b6.appspot.com",
    messagingSenderId: "648767113254",
    appId: "1:648767113254:web:b8b1e84d02fae671027ee7",
    measurementId: "G-6RD8J6VXZM",
  };
  // Initialize Firebase
  const fire = firebase.initializeApp(firebaseConfig);

  export default fire;
