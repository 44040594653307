import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { numberWithCommas } from "../../../functions/Display";

export default function CFInputForm({ CF, modifyCFArr, pos, disabled }) {
  //const pos = 0;

  //const [CF, setCF] = useState({});
  //const modifyCFArr = () => {};
  const [render, setRender] = useState(false);

  const handleChange = (name, val) => {
    var newCF = CF;

    if (val === "") {
      newCF[name.toUpperCase()] = "";
      newCF[name] = null;
      modifyCFArr(newCF, pos);
      setRender(!render);
    } else {
      var thisval = parseFloat(val.toString().replace(/,/g, ""));

      newCF[name.toUpperCase()] = thisval;
      if (val.toString().charAt(val.toString().length - 1) === ".") {
        newCF[name.toUpperCase()] = newCF[name.toUpperCase()] + ".";
      }
      newCF[name] = thisval;

      modifyCFArr(newCF, pos);

      setRender(!render);
    }
  };

  const handleBlur = (name) => {
    // when blurred (out of focus), format the text field
    var newCF = CF;
    var thisval = newCF[name];

    if (thisval) {
      //newCF[name.toUpperCase()] = numberWithCommas(thisval);
      newCF[name.toUpperCase()] = thisval;
      modifyCFArr(newCF, pos);
      setRender(!render);
    }
  };

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} controlId="g">
          <Form.Label>
            {" "}
            <b>Growth Rate (%)</b>
          </Form.Label>
          <Form.Control
            disabled={disabled.includes("g")}
            onChange={(e) => {
              handleChange("g", e.currentTarget.value);
            }}
            onBlur={(e) => {
              handleBlur("g", e.currentTarget.value);
            }}
            value={CF.G ?? null}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="n">
          <Form.Label>
            <b>Number of Payments (N)</b>
          </Form.Label>
          <Form.Control
            disabled={disabled.includes("n")}
            onChange={(e) => {
              handleChange("n", e.currentTarget.value);
            }}
            onBlur={(e) => {
              handleBlur("n", e.currentTarget.value);
            }}
            value={CF.N ?? null}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="pmt">
          <Form.Label>
            <b>(First) Payment Amount</b>
          </Form.Label>
          <Form.Control
            disabled={disabled.includes("pmt")}
            onChange={(e) => {
              handleChange("pmt", e.currentTarget.value);
            }}
            onBlur={(e) => {
              handleBlur("pmt", e.currentTarget.value);
            }}
            value={CF.PMT ?? null}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="pv">
          <Form.Label>
            <b>Payment Starting Time</b>{" "}
          </Form.Label>
          <Form.Control
            disabled={disabled.includes("start")}
            onChange={(e) => {
              handleChange("start", e.currentTarget.value);
            }}
            onBlur={(e) => {
              handleBlur("start", e.currentTarget.value);
            }}
            value={CF.START ?? null}
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
