const Finance = require("tvm-financejs");

export const createBankBalance = (tvm, tvmName) => {
  // create a vector with length period + 1 (from 0 to period)
  const time = [];
  const finance = new Finance();
  const period = Math.ceil(Math.floor(tvm["period"] * 10000) / 10000);
  for (let i = 0; i <= period; i++) {
    time.push(i);
  }

  // calculate amt

  const result = time.map((t) => ({
    name: t.toString(),
    [tvmName]:
      Math.round(
        -finance.FV(tvm["rateEff"] / 100, t, tvm["pmt"], tvm["pv"]) * 100
      ) / 100,
  }));

  return result;
};

export const createBankBalanceSimpleInterest = (tvm, tvmName) => {
  // create a vector with length period + 1 (from 0 to period)
  const time = [];

  const period = Math.ceil(Math.floor(tvm["period"] * 10000) / 10000);
  for (let i = 0; i <= period; i++) {
    time.push(i);
  }

  // calculate amt

  const result = time.map((t) => ({
    name: t.toString(),
    [tvmName]:
      Math.round((tvm["pv"] + ((tvm["pv"] * tvm["rateEff"]) / 100) * t) * 100) /
      100,
  }));

  return result;
};

export const createMortgagePmt = (tvm) => {
  // a function that visualizes mortgage payments
  // decomposes mortgage payments into interest and princple payment

  const finance = new Finance();
  const time = [];

  console.log(tvm);

  tvm.pmt = finance.PMT(tvm.rateEff / 100, tvm.period, -tvm.pv, 0);

  const period = Math.ceil(Math.floor(tvm["period"] * 10000) / 10000);

  // i starts on time 1
  for (let i = 1; i <= period; i++) {
    time.push(i);
  }

  const result = time.map((t) => ({
    name: `${t}`,
    pmt: tvm.pmt,
    principle: -finance.PV(tvm.rateEff / 100, tvm.period - t + 1, tvm.pmt, 0),
    "Interest Pmt":
      Math.round(
        -(
          finance.PV(tvm.rateEff / 100, tvm.period - t + 1, tvm.pmt, 0) *
          tvm.rateEff * 10 
        )
      ) / 1000,
    "Principle Pmt":
      Math.round(
        tvm.pmt * 1000 +
          finance.PV(tvm.rateEff / 100, tvm.period - t + 1, tvm.pmt, 0) *
            tvm.rateEff * 10
      ) / 1000,
  }));

  return result;
};

export const createPvPmt = (tvm, firstT, index) => {
  // a function that visualizes present values of payments (and the percentage of present values from first n payments)
  // firstT is the firstT payments that needs to be visualized
  const finance = new Finance();
  // if period is 0, it is a perpetuity
  if (!tvm["g"]) tvm["g"] = 0;

  // First Get present values
  // if g = 0, it's ordinary PVA PVP
  // if period = 0, it's perpetuity
  if (Math.round(tvm.period) === 0) {
    tvm.pv = tvm.pmt / (tvm.rateEff / 100 - tvm.g / 100);
  } else {
    tvm.pv =
      (tvm.pmt / (tvm.rateEff / 100 - tvm.g / 100)) *
        (1 -
          Math.pow((1 + tvm.g / 100) / (1 + tvm.rateEff / 100), tvm.period)) -
      finance.PV(tvm.rateEff / 100, tvm.period, 0, tvm.fv);
  }

  const result = {};

  // const period = Math.ceil(Math.floor(tvm["period"] * 10000) / 10000);

  // i starts on time 1
  var plottingT = firstT;
  if (plottingT > tvm.period && tvm.period !== 0) {
    plottingT = tvm.period;
  }
  var remainpv = tvm.pv;
  for (let i = 1; i <= plottingT; i++) {
    result["pmt" + i.toString()] = tvm.pmt * Math.pow(1 + tvm.g / 100, i);
    result["pvpmt" + i.toString()] =
      Math.round(-finance.PV(tvm.rateEff / 100, i, 0, tvm.pmt * Math.pow(1 + tvm.g / 100, i)) * 100) / 100;
    result["CF " + i.toString()] =
      Math.round(
        (-finance.PV(
          tvm.rateEff / 100,
          i,
          0,
          tvm.pmt * Math.pow(1 + tvm.g / 100, i)
        ) /
          tvm.pv) *
          10000
      ) / 100 || 0;
    remainpv = remainpv + finance.PV(tvm.rateEff / 100, i, 0, tvm.pmt * Math.pow(1 + tvm.g / 100, i));
  }

  result["CF Terminal"] =
    Math.round(
      (-finance.PV(tvm.rateEff / 100, tvm.period, 0, tvm.fv) / tvm.pv) * 10000
    ) / 100 || 0;
  remainpv = remainpv + finance.PV(tvm.rateEff / 100, tvm.period, 0, tvm.fv);
  result["pmt1000"] = 0;
  result["pvpmt1000"] = Math.round(remainpv * 100) / 100;

  result["CF Remaining"] = Math.round((remainpv / tvm.pv) * 10000) / 100 || 0;
  result["name"] = `Fraction ${index + 1}`;
  return result;
};

export const tvmFunc = (tvm, target) => {
  // tvm is an tvm object to be completed
  // target specifies what should be completed
  // target can be 'pv', 'fv', 'pmt', 'period', 'rateEff'

  const newTvm = tvm;
  const finance = new Finance();
  if (!tvm["pv"]) newTvm["pv"] = 0;
  if (!tvm["fv"]) newTvm["fv"] = 0;
  if (!tvm["pmt"]) newTvm["pmt"] = 0;

  if (target === "fv") {
    console.log(tvm);
    newTvm["fv"] = -finance.FV(
      newTvm["rateEff"] / 100,
      newTvm["period"],
      newTvm["pmt"],
      newTvm["pv"]
    );
  }

  if (target === "pv") {
    newTvm["pv"] = -finance.PV(
      newTvm["rateEff"] / 100,
      newTvm["period"],
      newTvm["pmt"],
      newTvm["fv"]
    );
    // newTvm["PV"] = numberWithCommasAndDigits(newTvm["pv"], 2);
  }

  if (target === "period") {
    newTvm["period"] = finance.NPER(
      newTvm["rateEff"] / 100,
      newTvm["pmt"],
      -newTvm["pv"],
      newTvm["fv"]
    );
    //newTvm["PERIOD"] = numberWithCommasAndDigits(newTvm["period"], 2);
  }

  if (target === "rateEff") {
    newTvm["rateEff"] =
      finance.RATE(
        newTvm["period"],
        newTvm["pmt"],
        -newTvm["pv"],
        newTvm["fv"]
      ) * 100;
    //newTvm["RATEEFF"] = numberWithCommasAndDigits(newTvm["rateEff"], 2);
  }

  if (target === "pmt") {
    newTvm["pmt"] = finance.PMT(
      newTvm["rateEff"],
      newTvm["period"],
      -newTvm["pv"],
      newTvm["fv"]
    );
  }
  return newTvm;
};

export const simpleTvmFunc = (tvm, target) => {
  // this is a module that calculates simple interest rates

  const newTvm = tvm;
  if (!tvm["pv"]) newTvm["pv"] = 0;
  if (!tvm["fv"]) newTvm["fv"] = 0;
  if (!tvm["pmt"]) newTvm["pmt"] = 0;

  if (target === "fv") {
    newTvm["fv"] =
      newTvm["pv"] +
      ((newTvm["pv"] * newTvm["rateEff"]) / 100) * newTvm["period"];
  }

  if (target === "pv") {
    newTvm["pv"] =
      newTvm["fv"] / (1 + (newTvm["rateEff"] * newTvm["period"]) / 100);
  }

  if (target === "period") {
    newTvm["period"] =
      ((newTvm["fv"] / newTvm["pv"] - 1) / newTvm["rateEff"]) * 100;
  }

  if (target === "rateEff") {
    newTvm["rateEff"] =
      ((newTvm["fv"] / newTvm["pv"] - 1) / newTvm["period"]) * 100;
  }
  return newTvm;
};
