import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";

export default function SetCF({ input, setInput, handleClose }) {
  const [n, setN] = useState(input.forms.length);
  const [inputLocal, setInputLocal] = useState(input);
  const [render, setRender] = useState(false); // render forces components to rerender

  const handleChangeN = (e) => {
    var inputTemp;

    if (e.currentTarget.value <= n) {
      inputTemp = inputLocal;
      var formsTemp = inputLocal.forms.slice(0, e.currentTarget.value);
      inputTemp.forms = formsTemp;
    } else {
      inputTemp = inputLocal;
      var formsTemp = inputLocal.forms.slice(0, e.currentTarget.value);
      for (var i = n; i < e.currentTarget.value; i++) {
        formsTemp.push({ name: `Cash Flow ${i + 1}`, disabled: [] });
      }
      inputTemp.forms = formsTemp;
    }
    setN(e.currentTarget.value);
    setInputLocal(inputTemp);
  };

  const handleChangeName = (i, val) => {
    var inputTemp = inputLocal;
    var formsTemp = inputLocal.forms;
    formsTemp[i] = { name: val, disabled: [] };
    inputTemp.forms = formsTemp;
    setInputLocal(inputTemp);
    setRender(!render);
  };

  const handleChangeTitle = (e) => {
    var inputTemp = inputLocal;
    inputTemp.title = e.currentTarget.value;
    setInputLocal(inputTemp);
    setRender(!render);
  };

  const handleChangeUnit = (e) => {
    var inputTemp = inputLocal;
    inputTemp.unit = e.currentTarget.value;
    setInputLocal(inputTemp);
    setRender(!render);
  };

  const handleSubmit = () => {
    setInput(inputLocal);
    handleClose();
  };

  return (
    <Form>
      <Form.Group controlId="numCFs">
        <Form.Control as="select" defaultValue={n} onChange={handleChangeN}>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {" "}
          <b>Chart Title</b>
        </Form.Label>
        <Form.Control
          value={inputLocal.title}
          onChange={handleChangeTitle}
        ></Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {" "}
          <b>Cash Flow Unit</b>
        </Form.Label>
        <Form.Control
          value={inputLocal.unit}
          onChange={handleChangeUnit}
        ></Form.Control>
      </Form.Group>
      {inputLocal.forms.map((inp, i) => (
        <Form.Row>
          <Form.Group as={Col} controlId="rateEff">
            <Form.Label>
              {" "}
              <b>Cash Flow Name {i + 1}</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                handleChangeName(i, e.currentTarget.value);
              }}
              value={inp.name}
            />
          </Form.Group>
        </Form.Row>
      ))}
      <Button onClick={handleSubmit}> Submit </Button>
    </Form>
  );
}
