import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useState } from "react";
import { theme } from "../../../functions/Display";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const StackedBar = ({ data, layers }) => {
  const [fill, setFill] = useState([]);
  const defs = [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "#ffbcb2",
      size: 2,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "#fed312",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
    {
      id: "lines2",
      type: "patternLines",
      background: "inherit",
      color: "#e8bcb2",
      rotation: 45,
      lineWidth: 3,
      spacing: 15,
    },
  ];
  useEffect(() => {
    var tempfill = [];
    var d = 0;
    data.keys.forEach((k, i) => {
      if (i % 2 === 1 && defs[d]) {
        tempfill.push({
          match: {
            id: k,
          },
          id: defs[d].id,
        });
        d = d + 1;
      }
    });
    setFill(tempfill);

    return () => {};
  }, []);
  //console.log(data);
  return (
    <ResponsiveBar
      theme={theme}
      data={data.data}
      keys={data.keys}
      indexBy="name"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      // colors={{ scheme: "spectral" }}
      colors={{ scheme: "paired" }}
      defs={defs}
      fill={fill}
      borderColor={{ from: "theme", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `${data.xlabel}`,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: data.ylabel,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "theme", modifiers: [["darker", 2]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layers={
        layers
          ? layers
          : ["grid", "axes", "bars", "markers", "legends", "annotations"]
      }
    />
  );
};

export default StackedBar;
