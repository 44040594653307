import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { createCFPlot } from "../../functions/CFFunctions";

import CFInputForm from "../common/forms/CFInputForm";

import SimpleBar from "../common/plots/SimpleBar";

const input = {
  unit: "USD",
  title: "Cash Flow Payment",
  forms: [
    { name: "Coupon Payments", disabled: ["g"] },
    { name: "Face Value", disabled: ["n", "start", "g"] },
  ],
};

export default function BondCF() {
  // a module to visualize cash flows, including perpetuity, annuity, bonds, stocks

  const modifyCFArr = (CF, pos) => {
    // this function changes

    const newCFArr = CFArr;
    newCFArr[pos] = CF;
    // make suare that coupon payment is consistent
    newCFArr[0].g = 0;
    newCFArr[0].G = "0";
    newCFArr[1].g = 0;
    newCFArr[1].G = "0";
    newCFArr[1].n = 1;
    newCFArr[1].N = "1";
    newCFArr[1].start = newCFArr[0].start + newCFArr[0].n - 1;
    newCFArr[1].START = (newCFArr[0].start + newCFArr[0].n - 1).toString();
    console.log(newCFArr);
    setCFArr(newCFArr);
  };

  const handlePlot = () => {
    const plotDataTemp = [];
    plotDataTemp["data"] = createCFPlot(CFArr, from, to);
    console.log(CFArr);
    plotDataTemp["bars"] = input.forms.map((f) => f.name);

    plotDataTemp["ylabel"] = input.unit;
    plotDataTemp["xlabel"] = input.title;

    setPlotData(plotDataTemp);

    setRender(!render);
  };

  const [CFArr, setCFArr] = useState([]);
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender
  const [from, setFrom] = useState(0); // from cash flow, default set to 0
  const [to, setTo] = useState("Auto"); // last cash flow
  const [initPlot, setInitPlot] = useState(false);

  const [setting, setSetting] = useState(false);

  useEffect(() => {
    // initial plot
    handlePlot();
    return () => {};
  }, [initPlot]);

  useEffect(() => {
    // initialize the CF array
    const temp = input.forms.map((f, i) => ({ index: i, name: f.name }));
    // initialize the forms
    temp[0].n = 10;
    temp[0].N = "10";
    temp[0].pmt = 10;
    temp[0].PMT = "10";
    temp[0].start = 1;
    temp[0].START = "1";
    temp[1].pmt = 100;
    temp[1].PMT = "100";
    temp[1].start = 10;
    temp[1].START = "10";
    temp[1].n = 1;
    temp[1].N = "1";
    temp[1].g = 0;
    temp[1].G = "0";
    temp[0].g = 0;
    temp[0].G = "0";
    setCFArr(temp);
    setInitPlot(true);
    return () => {};
  }, []);

  return (
    <Container>
      <Row>
        {" "}
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && <SimpleBar data={plotData} />}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          CFArr[i] && (
            <>
              <Row>
                <h4>
                  <Badge variant="primary">{CFArr[i].name}</Badge>
                </h4>
              </Row>
              <Row>
                <Col>
                  <CFInputForm
                    modifyCFArr={modifyCFArr}
                    pos={i}
                    CF={CFArr[i]}
                    newCal={newCal}
                    setNewCal={setNewCal}
                    disabled={f.disabled}
                  />
                </Col>
              </Row>
            </>
          )
      )}

      <Row>
        <Button variant="outline-primary" className="mr-5" onClick={handlePlot}>
          <h5>Plot</h5>
        </Button>
        <Button
          variant="outline-dark"
          onClick={() => {
            setSetting(!setting);
          }}
        >
          <h5>Display Setting</h5>
        </Button>
      </Row>
      {setting && (
        <Form.Row>
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <b> Plot CFs From </b>
            </Form.Label>
            <Form.Control
              placeholder={0}
              value={from}
              onChange={(e) => {
                setFrom(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <b> Plot CFs to </b>
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                setTo(e.currentTarget.value);
              }}
              value={to}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Group as={Row}></Form.Group>
          </Form.Group>
        </Form.Row>
      )}
    </Container>
  );
}
