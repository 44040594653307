// this set of codes generates arrays for cash flow plots

export const createCFPlot = (CFArr, from, to) => {
  // take in an array of cash flows
  // generate all the data that is necessary between beginning and the end

  // if start and end are not specified, we will use 0 for start, and largest number of array as the end

  // get the end date

  var end = 0;
  var currentCFs = [];

  // initialize current CF

  if (to === "Auto") {
    end = CFArr.reduce(
      (acc, curr) =>
        Math.max(
          acc,
          curr.n === 0 ? curr.start + 10 - 1 : curr.start + curr.n - 1
        ),
      0
    );
  } else {
    end = to;
  }

  // go through all the arrays and
  const map = new Map();

  for (var i = from; i <= end; i++) {
    var item = {};
    CFArr.forEach((c, j) => {
      if (c.start > i || (c.start + c.n - 1 < i && c.n !== 0)) {
        item[c.name] = 0;
        currentCFs[j] = 0;
      } else if (c.start === i) {
        item[c.name] = Math.round(c.pmt * 100) / 100;
        currentCFs[j] = c.pmt;
      } else if (c.start <= i && (c.start + c.n - 1 >= i || c.n === 0)) {
        currentCFs[j] = currentCFs[j] * (1 + c.g / 100);
        item[c.name] = Math.round(currentCFs[j] * 100) / 100;
      }
    });

    item["name"] = i;

    map.set(i, item);
  }

  const mergedArr = Array.from(map.values());
  // console.log(mergedArr);
  return mergedArr;
};
