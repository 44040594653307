import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import React from "react";

import "./App.css";

import Home from "./components/Home";

import Tvm from "./components/CF_TVM/CustomizeTvm";
import Bond from "./components/CF_TVM/BondCF";
import Stock from "./components/CF_TVM/GrowthStock";
import CFs from "./components/CF_TVM/CustomizeCF";
import PmtPct from "./components/CF_TVM/PmtPct";
import MtgPmt from "./components/CF_TVM/MtgPmt";
import BondPricing from "./components/CF_TVM/BondPricing";

import CashFlow from "./components/FIN_STMT/CashFlow";
import Income from "./components/FIN_STMT/Income";
import Balance from "./components/FIN_STMT/Balance";

import "bootstrap/dist/css/bootstrap.css";
import { Navbar } from "react-bootstrap";
import SEO from "./SEO";
import fire from "./firebase";

fire.analytics();

function App() {
  return (
    <div>
      <SEO />

      <Navbar className="justify-content-center" bg="primary" variant="dark">
        <Navbar.Brand href="/">
          {" "}
          <b>FIVIZ</b>
        </Navbar.Brand>
      </Navbar>

      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/stock" component={Stock} />
          <Route path="/cfs" component={CFs} />
          <Route path="/tvm" component={Tvm} />
          <Route path="/cfs" component={CFs} />
          <Route path="/stock" component={Stock} />
          <Route path="/bond" component={Bond} />
          <Route path="/pmtpct" component={PmtPct} />
          <Route path="/mtgpmt" component={MtgPmt} />
          <Route path="/bondpricing" component={BondPricing} />
          <Route path="/cashflow" component={CashFlow} />
          <Route path="/income" component={Income} />
          <Route path="/balance" component={Balance} />
          <Route
            path="/e"
            render={() =>
              (window.location =
                "https://dzfinance.notion.site/FIN-4610-EMWA-8e64cede223d47508da3336dab5f0457?pvs=4")
            }
          />
          <Route
            path="/f"
            render={() =>
              (window.location =
                "https://dzfinance.notion.site/FIN-4610-FMWA-0728c0987d6c48fc99d2396316764589?pvs=4")
            }
          />
          {/* <Route
            path="/quiz"
            render={() =>
              (window.location =
                "https://dzfinance.github.io/redirect.html")
            }
          />           */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
