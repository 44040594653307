import React, { useState, useEffect } from "react";
import SimpleSankey from "../common/plots/SimpleSankey";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Container, Form, Row, Col } from "react-bootstrap";

// this module demonstrates how choice of leverage affects

const defaultData = {
  nodes: [
    {
      id: "CF From Assets",
      color: "hsl(113, 70%, 50%)",
    },
    {
      id: "CF to Creditors",
      color: "hsl(115, 70%, 50%)",
    },
    {
      id: "CF to Stockholders",
      color: "hsl(75, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "CF From Assets",
      target: "CF to Creditors",
      value: 193,
    },
    {
      source: "CF From Assets",
      target: "CF to Stockholders",
      value: 190,
    },
  ],
};

const colors = {
  OCF: "#34eb74",
  "-Net Cap Spending": "#ffa200",
  "-Change in Net WC": "#ff5100",
  "CF From Assets": "#00aaff",
  "CF to Creditors": "#00d9ff",
  "CF to Stockholders": "#d600ab",
};

export default function CashFlow() {
  const [profit, setProfit] = useState(0.5);
  const [data, setData] = useState(defaultData);
  const [equity, setEquity] = useState(50);
  const [debt, setDebt] = useState(50);
  const [int, setInt] = useState(0.5);
  const [render, setRender] = useState(false);
  const [showLeft, setShowLeft] = useState(true);

  const handleSankeyClick = (data) => {
    console.log(data);
    if (data.id === "CF From Assets") {
      setShowLeft(!showLeft);
    }
  };
  useEffect(() => {
    const CFToStockHolder =
      ((parseFloat(equity) + parseFloat(debt)) * profit) / 100 -
      (parseFloat(debt) * int) / 100;

    if (showLeft) {
      setData({
        nodes: [
          {
            id: "OCF",
            color: "hsl(113, 70%, 50%)",
          },
          {
            id: "-Net Cap Spending",
            color: "hsl(115, 70%, 50%)",
          },
          {
            id: "-Change in Net WC",
            color: "hsl(113, 70%, 50%)",
          },
          {
            id: "CF From Assets",
            color: "hsl(113, 70%, 50%)",
          },
          {
            id: "CF to Creditors",
            color: "hsl(115, 70%, 50%)",
          },
          {
            id: "CF to Stockholders",
            color: "hsl(75, 70%, 50%)",
          },
        ],
        links: [
          {
            source: "OCF",
            target: "CF From Assets",
            value: Math.floor((profit / 3) * 100) / 100,
          },
          {
            source: "-Net Cap Spending",
            target: "CF From Assets",
            value: Math.floor((profit / 3) * 100) / 100,
          },
          {
            source: "-Change in Net WC",
            target: "CF From Assets",
            value: Math.floor((profit / 3) * 100) / 100,
          },
          {
            source: "CF From Assets",
            target: "CF to Creditors",
            value: (parseFloat(debt) * parseFloat(int)) / 100,
          },
          {
            source: "CF From Assets",
            target: "CF to Stockholders",
            value: CFToStockHolder,
          },
        ],
      });
    } else {
      setData({
        nodes: [
          {
            id: "CF From Assets",
            color: "hsl(113, 70%, 50%)",
          },
          {
            id: "CF to Creditors",
            color: "hsl(115, 70%, 50%)",
          },
          {
            id: "CF to Stockholders",
            color: "hsl(75, 70%, 50%)",
          },
        ],
        links: [
          {
            source: "CF From Assets",
            target: "CF to Creditors",
            value: (parseFloat(debt) * parseFloat(int)) / 100,
          },
          {
            source: "CF From Assets",
            target: "CF to Stockholders",
            value: CFToStockHolder,
          },
        ],
      });
    }

    setRender(!render);
    return () => {};
  }, [profit, equity, debt, int, showLeft]);

  return (
    <Container>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          <SimpleSankey
            data={data}
            onClick={handleSankeyClick}
            colors={(data) => colors[data["id"]]}
          />
        </div>
      </Row>

      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <b> Leverage (Equity / Assets %)</b>
            </Form.Label>

            <RangeSlider
              value={equity}
              onChange={(e) => {
                setEquity(e.target.value);
                setDebt(100 - e.target.value);
              }}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>

          <Form.Group className="ml-5" as={Col}>
            <Form.Label>
              <b>Return on Assets (%) </b>
            </Form.Label>
            <RangeSlider
              value={profit}
              onChange={(e) => setProfit(e.target.value)}
              min={0.5}
              max={100}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <b>Interest Rate (%)</b>
            </Form.Label>
            <RangeSlider
              value={int}
              onChange={(e) => setInt(e.target.value)}
              min={0.5}
              max={20}
              step={0.5}
              size="lg"
            />
          </Form.Group>
        </Form.Row>
      </Form>
    </Container>
  );
}
