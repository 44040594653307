import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";

import SetCF from "./SetCF";

import CFVisualize from "./CFVisualize";

const defaultInput = {
  unit: "USD",
  title: "Cash Flow Payment",
  forms: [{ name: "Cash Flow 1", disabled: [] }],
};

export default function CustomizeCF() {
  const [render, setRender] = useState(false);
  const [input, setInput] = useState(defaultInput);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setRender(!render);
    console.log(input);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <CFVisualize input={input} rerender={render} handleShow={handleShow} />
      {/* <Row className="justify-content-center">
        <Button onClick={handleShow}> Customize</Button>
      </Row> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Customize Cashflow Input</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SetCF input={input} setInput={setInput} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}
