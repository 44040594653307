import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { createMortgagePmt } from "../../functions/TvmFunctions";

import PvFvInputForm from "../common/forms/PvFvInputForm";

import StackedBar from "../common/plots/StackedBar";

const input = {
  unit: "Mortgage Payment",
  title: "Decomposition Mortgage Payments",
  forms: [
    { name: "Mortgage Payment 1" },
    //   { name: "Mortgage Payment 2" },
  ],
};

export default function MtgPmt() {
  const modifyTvmArr = (tvm, pos) => {
    // this function changes
    const newTvmArr = tvmArr;
    newTvmArr[pos] = tvm;
    setTvmArr(newTvmArr);
  };

  const [tvmArr, setTvmArr] = useState([]);
  const [plotData, setPlotData] = useState(null);
  const [newCal, setNewCal] = useState(false); // newCal is an indicator when a new calculation is changed
  const [render, setRender] = useState(false); // render forces components to rerender

  useEffect(() => {
    // initialize the tvm array
    const temp = input.forms.map((f, i) => ({ index: i }));
    temp[0].pv = 1000;
    temp[0].PV = "1,000";
    temp[0].period = 10;
    temp[0].PERIOD = "10";
    temp[0].rateEff = 5;
    temp[0].RATEEFF = "5";
    setTvmArr(temp);
    setNewCal(!newCal);
    // console.log(temp);

    return () => {};
  }, []);

  useEffect(() => {
    {
      const plotDataTemp = [];
      plotDataTemp["data"] = tvmArr[0]
        ? createMortgagePmt(tvmArr[0])
        : createMortgagePmt({
            period: 3,
            pv: 1,
            pmt: 0,
            g: 0,
            rateEff: 1,
            fv: 0,
          });

      //console.log(plotDataTemp)

      //   plotDataTemp["bars"] = input.forms.map((f, i) => ({
      //     name: i,
      //     dataKey: "fraction",
      //   }));

      plotDataTemp["ylabel"] = input.unit;
      plotDataTemp["xlabel"] = input.title;
      const keys = ["Principle Pmt", "Interest Pmt"];

      plotDataTemp["keys"] = keys;
      setPlotData(plotDataTemp);
      console.log(plotDataTemp);
      setRender(!render);
    }
    return () => {};
  }, [newCal]);

  return (
    <Container>
      <Row>
        <div
          style={{
            width: "90%",
            height: "30em",
            float: "left",
            overflowY: "auto",
          }}
        >
          {plotData && <StackedBar data={plotData} />}
        </div>
      </Row>

      {input.forms.map(
        (f, i) =>
          tvmArr[i] && (
            <PvFvInputForm
              modifyTvmArr={modifyTvmArr}
              pos={i}
              tvm={tvmArr[i]}
              newCal={newCal}
              setNewCal={setNewCal}
              disabled={["fv", "g", "pmt"]}
              pvName="Loan Amount"
              periodName="Number of Payments"
            />
          )
      )}
      <Row className = "justify-content-center">
      <Button variant="outline-primary" onClick={() => setNewCal(!newCal)}><h5>Plot</h5></Button>
      </Row>
    </Container>
  );
}
