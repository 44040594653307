import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { theme } from "../../../functions/Display";

export default function SimpleBar({ data, maxValue, minValue }) {
  // data is the orginal dataset
  // bars is an array of properties
  //console.log(data)
  return (
    <ResponsiveBar
      theme={theme}
      data={data.data}
      keys={data.bars}
      indexBy="name"
      margin={{ top: 50, right: 180, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "set2" }}
      borderColor={{ from: "theme", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `${data.xlabel}`,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: data.ylabel,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableGridY={true}
      groupMode="grouped"
      labelFormat={(d) => <tspan y={-10}>{d}</tspan>}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "theme", modifiers: [["darker", 3]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 170,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 155,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      maxValue={maxValue || "auto"}
      minValue={minValue || "auto"}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}
