import React from "react";

import { ResponsiveSankey } from "@nivo/sankey";
import { theme } from '../../../functions/Display';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function SimpleSankey({
  data,
  vertical,
  align,
  onClick,
  colors,
  nodeThickness,
  nodeTooltip
}) {
  const handleOnClick = (data) => {
    if (onClick) {
      onClick(data);
    }
  };

  const handleNodeTooltip = (node) => {
    if (nodeTooltip) {
      nodeTooltip(node);
    }
  }
  return (
    <ResponsiveSankey
      theme={theme}
      data={data}
      onClick={(data) => handleOnClick(data)}
      margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
      align={align || "start"}
      colors={colors || { scheme: "paired" }}
      nodeOpacity={1}
      nodeThickness={nodeThickness || 18}
      nodeInnerPadding={3}
      nodeSpacing={24}
      sort="input"
      nodeBorderWidth={0}
      nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
      linkOpacity={0.5}
      linkHoverOthersOpacity={0.1}
      enableLinkGradient={true}
      labelPosition="outside"
      labelOrientation={vertical ? "horizontal" : "vertical"}
      layout={vertical ? "vertical" : "horizontal"}
      labelPadding={16}
      labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      linkBlendMode="normal"
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          translateX: 150,
          itemWidth: 100,
          itemHeight: 14,
          itemDirection: "right-to-left",
          itemsSpacing: 2,
          itemTextColor: "#999",
          symbolSize: 14,
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#200",
              },
            },
          ],
        },
      ]}
    />
  );
}
