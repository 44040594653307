import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";

import SetTvm from "./SetTvm";

import Tvm from "./Tvm";

const defaultInput = {
  unit: "USD",
  title: "Bank Account Value",
  simplePlot: false,
  forms: [
    { name: "Account Value (Compound)", simple: false, disabled: ["pmt"] },
    { name: "Account Value (Simple)", simple: true, disabled: ["pmt"] },
  ],
};

export default function CustomizeTvm() {
  const [render, setRender] = useState(false);
  const [input, setInput] = useState(defaultInput);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setRender(!render);
    console.log(input);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Tvm input={input} rerender={render} />
      <Row className="justify-content-center">
        <Button variant="outline-primary" onClick={handleShow}>
          <h5> Customize</h5>
        </Button>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Customize Cashflow Input</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SetTvm
            input={input}
            setInput={setInput}
            handleClose={handleClose}
            disabled={["pmt"]}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
