import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import {
  numberWithCommas,
  numberWithCommasAndDigits,
} from "../../../functions/Display";
import { tvmFunc } from "../../../functions/TvmFunctions";
//const Finance = require("tvm-financejs");

export default function PvFvInputForm({
  tvm,
  modifyTvmArr,
  pos,
  disabled,
  pvName,
  fvName,
  pmtName,
  rateEffName,
  periodName,
  gName,
}) {
  const [render, setRender] = useState(false);
  // const [tvm, modifyTvm] = useState([]);
  //const [tvmArr, modifyTvmArr] = useState([]);
  // const pos = 0;
  const handleBlur = (a, b) => {};

  const handleChange = (name, val) => {
    var newTvm = tvm;

    if (val === "") {
      newTvm[name.toUpperCase()] = "";
      newTvm[name] = null;
      modifyTvmArr(newTvm, pos);
      setRender(!render);
    } else {
      var thisval = parseFloat(val.toString().replace(/,/g, ""));

      newTvm[name.toUpperCase()] = numberWithCommas(thisval);
      if (val.toString().charAt(val.toString().length - 1) === ".") {
        newTvm[name.toUpperCase()] = newTvm[name.toUpperCase()] + ".";
      }
      newTvm[name] = thisval;

      modifyTvmArr(newTvm, pos);

      setRender(!render);
    }
  };

  //   const handleSubmit = (target) => {
  //     console.log(tvm);

  //     const newTvm = tvmFunc(tvm, target);
  //     modifyTvmArr(newTvm, pos);
  //     //console.log(newTvm)
  //     newTvm[target.toUpperCase()] = numberWithCommasAndDigits(newTvm[target], 2);

  //     setRender(!render);
  //     setNewCal(!newCal);
  //   };

  return (
    <Form>
      <Form.Row>
        {!disabled.includes("rateEff") && (
          <Form.Group as={Col} controlId="rateEff">
            <Form.Label>
              {" "}
              <Badge size="sm" variant="outline-dark">
                <b>{rateEffName || "Interst Rate (%)"}</b>
              </Badge>{" "}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                handleChange("rateEff", e.currentTarget.value);
              }}
              value={tvm.RATEEFF ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("period") && (
          <Form.Group as={Col} controlId="period">
            <Form.Label>
              {" "}
              <Badge size="sm" variant="outline-dark">
                <b>{periodName || "Period"}</b>{" "}
              </Badge>{" "}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                handleChange("period", e.currentTarget.value);
              }}
              onBlur={(e) => {
                handleBlur("period", e.currentTarget.value);
              }}
              value={tvm.PERIOD ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("pv") && (
          <Form.Group as={Col} controlId="pv">
            <Form.Label>
              <Badge size="sm" variant="outline-dark">
                {" "}
                <b>{pvName || "Upfront Payment"} </b>{" "}
              </Badge>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("pv", e.currentTarget.value);
              }}
              onBlur={(e) => {
                handleBlur("pv", e.currentTarget.value);
              }}
              value={tvm.PV ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("pmt") && (
          <Form.Group as={Col} controlId="pmt">
            <Form.Label>
              <Badge size="sm" variant="outline-dark">
                {" "}
                <b>{pmtName || "Payment"}</b>{" "}
              </Badge>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("pmt", e.currentTarget.value);
              }}
              value={tvm.PMT ?? null}
            />
          </Form.Group>
        )}
        {!disabled.includes("g") && (
          <Form.Group as={Col} controlId="g">
            <Form.Label>
              <Badge size="sm" variant="outline-dark">
                {" "}
                <b>{gName || "Growth Rate (%)"} </b>{" "}
              </Badge>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("g", e.currentTarget.value);
              }}
              value={tvm.G ?? 0}
            />
          </Form.Group>
        )}
        {!disabled.includes("fv") && (
          <Form.Group as={Col} controlId="fv">
            <Form.Label>
              <Badge size="sm" variant="outline-dark">
                {" "}
                <b>{fvName || "Terminal Payment"} </b>
              </Badge>
            </Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => {
                handleChange("fv", e.currentTarget.value);
              }}
              value={tvm.FV ?? null}
            />
          </Form.Group>
        )}
      </Form.Row>
    </Form>
  );
}
