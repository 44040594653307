import { getByPlaceholderText } from "@testing-library/react";

export const numberWithCommas = (x) => {
  try {
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return Math.floor(x * 100) / 100;
  } catch {
    return "";
  }
};

export const numberWithCommasAndDigits = (x, n) => {
  try {
    // return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return Math.floor(x * Math.pow(10, n)) / Math.pow(10, n);
  } catch {
    return "";
  }
};

export const barColors = [
  "#0fc1d9",
  "#EC8343",
  "#057DDC",
  "#6ACF4F",
  "#F9628E",
  "#FAFA08",
  "#9308FA",
];

export const theme = {
  labels: {
    text: {
      textColor: "#eee",
      fontSize: "12px",
      fontWeight: 700,
      strokeWidth: 5
    },
  },
  grid: {
    stroke: "#888",
    strokeWidth: 1,
  },
};
