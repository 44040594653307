// utility function

export function merge(objList, key, dataName) {
  // merge a list of arrays, each array is a dictionary {key: , dataName: }
  // E.g., one list is [{key: 5, data: 4}], [{key: 5, data: 3}] ==> [{key: 5, data1: 4, data2: 3}]

  const map = new Map();
  objList.forEach((obj, i) => {
    obj.forEach((item) => {
      // make a new item so that we can rename the dataname
        var itemTemp = item;
        itemTemp[dataName+i.toString()] = itemTemp[dataName]
        delete itemTemp[dataName];
      if (!map.has(itemTemp[key])) {
        map.set(itemTemp[key], itemTemp);
      } else {
        map.set(itemTemp[key], { ...map.get(itemTemp[key]), ...itemTemp });
      }
    });
  });
  const mergedArr = Array.from(map.values());
  return mergedArr;
}
